import {
  Text,
  Group,
  ScrollArea,
  Badge,
  Card,
  Flex,
  Stack,
} from "@mantine/core";

import {
  IconBuildingHospital,
  IconPencil,
  IconReceipt2,
  IconStethoscope,
} from "@tabler/icons-react";

import { ReactNode } from "react";
import { ISurgery, SurgeryStatus } from "../../../types";
import { LinkButton } from "../../../components/LinkButton";
import dayjs from "dayjs";

const badgeVariant = "light";
const statusComponent: { [status in SurgeryStatus]: ReactNode } = {
  paid: (
    <Badge color="green" radius="sm" variant={badgeVariant}>
      Pago
    </Badge>
  ),
  pending: (
    <Badge color="red" radius="sm" variant={badgeVariant}>
      Pendente
    </Badge>
  ),
  cancelled: (
    <Badge color="grey" radius="sm" variant={badgeVariant}>
      Cancelado
    </Badge>
  ),
  scheduled: (
    <Badge radius="sm" variant={badgeVariant}>
      Marcado
    </Badge>
  ),
};

export const CardList = ({ data }: { data: ISurgery[] | null }) => {
  return (
    // <ScrollArea h={'100%'} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
    <ScrollArea h={"100%"}>
      {data?.map((row) => (
        <Card withBorder radius="md" mb="sm" px="sm" key={row.id}>
          <Card.Section withBorder inheritPadding py="xs">
            <Flex justify="space-between">
              {row.receipt_id && (
                <Badge variant="outline" radius="sm">
                  {row.receipt_id}
                </Badge>
              )}
              {row.status && statusComponent[row.status]}
              <Text size="sm">
                {row.date
                  ? dayjs.utc(row.date).format("DD/MM/YYYY")
                  : "sem data"}
              </Text>
            </Flex>
            <Stack gap="xs">
              <Text mt={0} mb={0} fw={700}>
                {row.patient_name}
              </Text>
              <Group justify="left" align="center" gap={7}>
                <IconStethoscope stroke={2} size={20} color="#868e96" />
                <Text c="dimmed" fw={700} size="sm">
                  {row.doctor}
                </Text>
              </Group>
              <Group justify="left" align="center" gap={7}>
                <IconBuildingHospital stroke={2} size={20} color="#868e96" />
                <Text c="dimmed" size="sm">
                  {row.hospital}
                </Text>
              </Group>
            </Stack>
          </Card.Section>
          <Card.Section inheritPadding py="xs">
            <Group justify="space-between">
              <Text c="dimmed" fw={700}>
                {row.amount ? `R$${row.amount}` : " - "}
              </Text>
              <Group gap="xs">
                <LinkButton
                  to={`/app/edit-surgery/${row.id}`}
                  size="sm"
                  px="xs"
                >
                  <IconPencil stroke={1.5} /> Editar
                </LinkButton>
                <LinkButton
                  variant="filled"
                  color="green"
                  size="sm"
                  px="xs"
                  to={`/app/receipt/${row.id}`}
                >
                  <IconReceipt2 stroke={1.5} /> Recibo
                </LinkButton>
              </Group>
            </Group>
          </Card.Section>
        </Card>
      ))}
    </ScrollArea>
  );
};
