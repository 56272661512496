import classes from "./table.module.css";
import {
  Table as MantineTable,
  Anchor,
  Text,
  Group,
  ScrollArea,
  Tooltip,
  Badge,
} from "@mantine/core";
import cx from "clsx";
import { IconNumber, IconPencil, IconReceipt2 } from "@tabler/icons-react";

import { ReactNode, useState } from "react";
import { ISurgery, SurgeryStatus } from "../../../types";
import { LinkActionIcon } from "../../../components/LinkButton";
import dayjs from "dayjs";

const badgeVariant = "light";
const statusComponent: { [status in SurgeryStatus]: ReactNode } = {
  paid: (
    <Badge color="green" radius="sm" variant={badgeVariant}>
      Pago
    </Badge>
  ),
  pending: (
    <Badge color="red" radius="sm" variant={badgeVariant}>
      Pendente
    </Badge>
  ),
  cancelled: (
    <Badge color="grey" radius="sm" variant={badgeVariant}>
      Cancelado
    </Badge>
  ),
  scheduled: (
    <Badge radius="sm" variant={badgeVariant}>
      Marcado
    </Badge>
  ),
};

export const Table = ({ data }: { data: ISurgery[] | null }) => {
  const [scrolled, setScrolled] = useState(false);

  const rows =
    data &&
    data.map((row) => {
      return (
        <MantineTable.Tr key={row.id}>
          <MantineTable.Td>{row.receipt_id || ""}</MantineTable.Td>
          <MantineTable.Td>
            <Group gap="sm">
              {/* <Avatar size={40} src={item.avatar} radius={40} /> */}
              <div>
                <Text fz="sm" fw={500}>
                  {row.hospital}
                </Text>
                <Text fz="xs" c="dimmed">
                  {row.doctor}
                </Text>
              </div>
            </Group>
          </MantineTable.Td>
          <MantineTable.Td>
            <Anchor component="button" fz="sm">
              {row.patient_name}
            </Anchor>
          </MantineTable.Td>
          <MantineTable.Td>
            {row.date ? dayjs.utc(row.date).format("DD/MM/YYYY") : "sem data"}
          </MantineTable.Td>
          <MantineTable.Td>
            {row.amount ? `R$${row.amount}` : " - "}
          </MantineTable.Td>
          <MantineTable.Td>
            {row.status && statusComponent[row.status]}
          </MantineTable.Td>
          <MantineTable.Td>
            <Group>
              <Tooltip label="Editar">
                <LinkActionIcon to={`/app/edit-surgery/${row.id}`}>
                  <IconPencil stroke={1.5} />
                </LinkActionIcon>
              </Tooltip>
              <Tooltip label="Gerar recibo">
                <LinkActionIcon to={`/app/receipt/${row.id}`}>
                  <IconReceipt2 stroke={1.5} />
                </LinkActionIcon>
              </Tooltip>
            </Group>
          </MantineTable.Td>
        </MantineTable.Tr>
      );
    });
  return (
    <ScrollArea
      h={"100%"}
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
    >
      <MantineTable style={{ minWidth: 800 }} verticalSpacing="xs">
        <MantineTable.Thead
          className={cx(classes.header, { [classes.scrolled]: scrolled })}
        >
          <MantineTable.Tr>
            <MantineTable.Th>
              <IconNumber />
            </MantineTable.Th>
            <MantineTable.Th>Hospital/Dr.</MantineTable.Th>
            <MantineTable.Th>Paciente</MantineTable.Th>
            <MantineTable.Th>Data</MantineTable.Th>
            <MantineTable.Th>Valor</MantineTable.Th>
            <MantineTable.Th>Status</MantineTable.Th>
            <MantineTable.Th></MantineTable.Th>
          </MantineTable.Tr>
        </MantineTable.Thead>
        <tbody>{rows}</tbody>
      </MantineTable>
    </ScrollArea>
  );
};
